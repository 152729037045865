export const returnMenuitems = () => {
  const menuList = [
    {
      icon: "mdi-home-outline",
      title: "Home",
      path: "/home",
      isActive: true,
    },
    {
      icon: "mdi-account-plus",
      title: "Registration",
      path: "/add-indivisual-user",
      isActive: true,
    },
    {
      icon: "mdi-account-badge-outline",
      title: "Beneficiary",
      path: "/beneficiarysummary",
      isActive: true,
    },
    {
      icon: "mdi-shield-check-outline",
      title: "KYC",
      path: "/kyc",
      isActive: true,
    },
    {
      icon: "fa fa-circle-dollar-to-slot",
      title: "Send Money",
      path: "/transaction",
      isActive: true,
    },
    {
      icon: "mdi-receipt",
      title: "Transaction Summary",
      path: "/transactionsummary",
      isActive: true,
    },
    {
      icon: "mdi-briefcase-check",
      title: "Company Registration",
      path: "/business-user-info",
      isActive: true,
    },
    {
      icon: "mdi-file-account-outline",
      title: "Profile",
      path: "/profile",
      isActive: true,
    },
    {
      icon: "mdi-help-circle-outline",
      title: "Help",
      path: "/help",
      isActive: true,
    },
  ];
  return menuList;
};
