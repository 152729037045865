<template>
  <header id="header" class="d-flex flex-column justify-content-center">
    <nav id="navbar" class="navbar nav-menu">
      <ul class="p-0">
        <li>
          <a
            @click="$router.push('/home')"
            class="nav-link elevation-12"
            :class="{ 'nav-link active': this.$route.path == '/home' }"
            ><i class="fa-solid fa-house-user font-icon"></i>
            <span>{{ $t("home") }}</span></a
          >
        </li>
        <li>
          <a
            @click="$router.push('/transaction')"
            class="nav-link elevation-12"
            :class="{ 'nav-link active': this.$route.path == '/transaction' }"
            ><i class="fa fa-circle-dollar-to-slot font-icon"></i>
            <span>{{ $t("send_money") }}</span></a
          >
        </li>
        <li>
          <a
            @click="$router.push('/beneficiarysummary')"
            class="nav-link elevation-12"
            :class="{
              'nav-link active': this.$route.path == '/beneficiarysummary',
            }"
            ><i class="fa-solid fa-user-group font-icon"></i>
            <span>{{ $t("beneficiary") }}</span></a
          >
        </li>
        <li>
          <a
            @click="$router.push('/transactionsummary')"
            class="nav-link elevation-12"
            :class="{
              'nav-link active': this.$route.path == '/transactionsummary',
            }"
          >
            <i class="fa-solid fa-building-columns font-icon-bank"></i>
            <span>{{ $t("summary") }}</span></a
          >
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "NavBarLeft",
  data() {
    return {
      notify: null,
    };
  },
  created() {
    this.getNotification();
  },
  monted() {
    this.getNotification();
  },

  methods: {
    reload() {
      window.location.reload();
    },
    async getNotification() {
      let responseData = await this.$store.dispatch(
        "Transactional/getNotificationStatus"
      );
      this.notify = responseData.data[0].notification;
      console.log("notify", this.notify);
    },
  },
};
</script>

<style>
.navbar {
  padding: 0.5rem 1rem !important;
}
#header {
  position: fixed;
  top: 120px;
  left: 0;
  bottom: 0;
  z-index: 1040;
  transition: all 0.5s;
  padding: 0;
  overflow-y: auto;
}
@media (max-width: 992px) {
  #header {
    width: 300px;
    background: #fff;
    border-right: 1px solid #e6e9ec;
    left: -300px;
  }
}

@media (min-width: 992px) {
  #main {
    margin-left: 100px;
  }
}

.nav-menu {
  padding: 0;
  display: block;
}
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
}
.font-icon {
  font-size: 18px;
}
.font-icon-bank {
  font-size: 20px !important;
}
.font-icon-bank:hover {
  color: #ff6b00;
}
.nav-menu a,
.nav-menu a:focus {
  display: flex;
  align-items: center;
  color: grey;
  padding: 10px 18px;
  margin-bottom: 8px;
  transition: 0.3s;
  font-size: 15px;
  border-radius: 50px;
  background: #f2f3f5;
  height: 56px;
  width: 100%;
  overflow: hidden;
  transition: 0.3s;
}
.nav-menu a i,
.nav-menu a:focus i {
  font-size: 20px;
}
.nav-menu a span,
.nav-menu a:focus span {
  padding: 0 5px 0 7px;
  color: grey;
}
@media (min-width: 992px) {
  .nav-menu a,
  .nav-menu a:focus {
    width: 56px;
  }
  .nav-menu a span,
  .nav-menu a:focus span {
    display: none;
    color: #fff;
  }
}
.nav-menu a:hover,
.nav-menu .active,
.nav-menu .active:focus,
.nav-menu li:hover > a {
  color: #ff6b00 !important;
  background: #fff;
}
.nav-menu a:hover span,
.nav-menu .active span,
.nav-menu .active:focus span,
.nav-menu li:hover > a span {
  color: #ff6b00;
}
.nav-menu a:hover,
.nav-menu li:hover > a {
  width: 100%;
  color: #ff6b00;
}
.nav-menu a:hover span,
.nav-menu li:hover > a span {
  display: block;
}
</style>
