<template>
  <v-app>
    <v-app-bar app fixed>
      <NavBar :profiledata="profiledata" />
    </v-app-bar>
    <NavBarLeft />

    <v-main>
      <router-view @getProfile="getProfile()"></router-view>
    </v-main>

    <v-footer app elevation="12" color="white">
      <Footer />
    </v-footer>
  </v-app>
</template>

<script>
import NavBar from "@/components/navigations/NavBar.vue";

import NavBarLeft from "@/components/navigations/NavBarLeft.vue";
import Footer from "@/components/navigations/Footer.vue";
export default {
  name: "Layout",
  data: () => {
    return {
      profiledata: [],
    };
  },
  created() {
    this.getProfile();
  },

  components: {
    Footer,
    NavBar,
    NavBarLeft,
  },
  methods: {
    getProfile() {
      this.$store
        .dispatch("Authentication/getProfile")
        .then((res) => {
          if (Array.isArray(res.data.data)) {
            this.profiledata = res.data.data;
            console.log("this.profiledata", res);
            sessionStorage.setItem(
              "is_email_confirm",
              this.profiledata[0].is_email_confirmation
            );
            sessionStorage.setItem(
              "App_Language",
              this.profiledata[0].language
            );
            this.$i18n.locale = this.profiledata[0].language;
          }
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>

<style>
.v-application--wrap > section {
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}
</style>
