<template>
  <v-app-bar :dark="returnColor()" elevation="10" app fixed>
    <v-toolbar-title class="color-primary font-weight-bold h3">
      {{ $t("qrate") }} <span v-if="partnerLogo">-</span>
      <img
        :src="partner_logopath"
        v-if="partnerLogo"
        alt="Partner"
        style="height: 40px; width: 40px"
      />
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-avatar :title="profileDetails.fName">
      <img
        :src="profilePicLink"
        style="height: 40px; width: 40px"
        v-if="profilePicLink"
      />

      <v-icon v-else>mdi-account</v-icon>
    </v-avatar>
    <span
      v-if="profileDetails.fName"
      class="dark--text d-none d-sm-none d-md-flex"
    >
      {{ profileDetails.fName }}
    </span>
    <v-btn
      icon
      :title="
        is_KYC_Completed === 'Approved'
          ? 'KYC is Completed.'
          : 'KYC is Pending.'
      "
    >
      <v-icon :color="is_KYC_Completed === 'Approved' ? 'green' : 'orange'"
        >mdi-shield-check</v-icon
      >
    </v-btn>
    <v-btn
      icon
      active-class="primary"
      @click="logoutDialog = true"
      title="Logout"
      v-if="is_partner"
    >
      <v-icon>mdi-power</v-icon>
    </v-btn>
    <v-btn
      icon
      @click="toggleFullScreen"
      v-if="is_partner & mobilebreakdown() ? true : false"
    >
      <v-icon :title="Fullscreen">{{ Icon_text }}</v-icon>
    </v-btn>
    <v-btn
      icon
      active-class="primary"
      @click="reload = true"
      title="Refresh"
      v-if="!is_partner"
    >
      <v-icon>mdi-restore</v-icon>
    </v-btn>
    <v-menu left bottom max-height="90vh">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" title="Menu">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item class="border-bottom">
          <v-list-item-content>
            <v-list-item-subtitle class="text-overline"
              >{{ $t("app_version") }}
              <strong>v{{ appVersion }}</strong></v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item-group
          color="primary"
          active-class="orange--text text--accent-4"
        >
          <v-list-item
            v-for="(menuItem, idx) in menuList"
            :key="idx"
            link
            :to="menuItem.path"
            class="text-decoration-none"
            :disabled="!menuItem.isActive"
          >
            <v-list-item-title
              ><v-icon>{{ menuItem.icon }}</v-icon>
              {{ menuItem.title }}</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <!-- Logout confirmation popup dialog -->
    <v-dialog v-model="logoutDialog" width="500">
      <loading
        :active="isTransSummary"
        :is-full-page="false"
        :opacity="0.7"
        color="#ff6b00"
      />
      <v-card>
        <v-card-title class="border border-bottom">{{
          $t("logout")
        }}</v-card-title>
        <!-- <v-divider></v-divider> -->
        <v-card-text class="h5 py-3"
          >{{ $t("are_you_sure_want_to_logout") }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="logout()">
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn color="primary" outlined @click="logoutDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reload" width="500">
      <v-card>
        <v-card-title class="border border-bottom">{{
          $t("reload")
        }}</v-card-title>
        <!-- <v-divider></v-divider> -->
        <v-card-text class="h5 py-3"
          >{{ $t("are_you_sure_want_to_reload") }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="reloadPage">
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn color="primary" outlined @click="reload = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import Loading from "vue-loading-overlay";
import alerts from "@/mixins/alerts";
const pjson = require("../../../package.json");
import { mobilebreakdown, webbreakdown } from "../../utils/breakpoints";
import { returnMenuitems } from "../../utils/constDropdowns/navBarRoutes";
import userservice from "@/services/UserService/userservice";
export default {
  name: "NavBar",
  mixins: [alerts],
  props: {
    userLoggedIn: {
      type: Boolean,
      default: true,
    },
    backBtn: {
      type: Boolean,
      default: true,
    },
    invites: {
      type: Boolean,
      default: true,
    },
    profiledata: {
      type: Array,
    },
  },

  data() {
    return {
      mobilebreakdown,
      webbreakdown,
      isTransSummary: false,
      refersh: "",
      appVersion: pjson.version,
      logoutDialog: false,
      reload: false,
      Fullscreen: "",
      Icon_text: "mdi-fullscreen",
      user_type: this.usertype,
      profileDetails: {
        fName: "",
        lName: "",
        mName: "",
        emailConfirm: false,
        account_type: "",
        kycConfirm: "",
        country: "",
        email: "",
        phone: "",
        profile_pic: "",
      },
      menuList: returnMenuitems(),
      profilePicLink: "",
      is_KYC_Completed: "",
      is_User_Reg_Completed: false,
      is_partner: true,
      partnerLogo: null,
      partner_logopath: null,
    };
  },
  components: {
    Loading,
  },
  mounted() {
    let partner = this.$getLocalStorage("is_partner");
    this.partnerLogo = this.$getLocalStorage("is_logo");
    this.partner_logopath = this.$getLocalStorage("logo");

    if (partner) {
      this.is_partner = false;
    }
    this.user_type = sessionStorage.getItem("user_type");
    if (this.user_type) {
      this.menu(this.user_type);
    }
  },
  computed: {
    usertype() {
      return this.$store.state.selectedUserType;
    },
  },
  watch: {
    profiledata: {
      handler(newVal) {
        console.log("newVal P", newVal[0]);
        if (Array.isArray(newVal) && newVal.length > 0) {
          this.profilePicLink = newVal[0].profile_pic
            ? `${newVal[0].profile_pic}`
            : "";
          this.profileDetails.fName = newVal[0].customer_name
            ? newVal[0].customer_name
            : "";
          console.log("newVal[0].first_name", this.profileDetails.fName);

          sessionStorage.setItem("user_fname", newVal[0].first_name);
          sessionStorage.setItem("user_lname", newVal[0].last_name);
          sessionStorage.setItem(
            "complete_registration",
            newVal[0].user_status
          );
          this.$store.commit("complete_registration", newVal[0].user_status);
          this.is_User_Reg_Completed = newVal[0].user_status;
          this.is_KYC_Completed = newVal[0].kyc_status;
        }
      },
    },
    usertype(newVal) {
      this.user_type = newVal;
      if (this.user_type) {
        this.menu(this.user_type);
      }
      console.log("nav Usertype", newVal);
    },
    is_User_Reg_Completed(newVal) {
      if (newVal && this.user_type === "IND") {
        this.menuList = this.menuList.filter(
          (ele) => ele.title != "Registration"
        );
      }

      console.log(newVal);
    },
  },
  methods: {
    returnColor() {
      if (process.env.VUE_APP_DARK === "true") {
        return true;
      } else {
        return false;
      }
    },
    menu(val) {
      if (val != "IND") {
        this.menuList = this.menuList.filter(
          (ele) => ele.title != "Registration"
        );
      } else {
        this.menuList = this.menuList.filter(
          (ele) => ele.title != "Company Registration"
        );
      }
    },
    // logout() {
    //   sessionStorage.clear();
    //   this.logoutDialog = false;
    //   this.$router.push({ name: "Login" });
    // },
    reloadPage() {
      this.reload = false;
      window.location.reload();
    },
    async logout() {
      this.Loadlogout = true;
      try {
        const responseData = await userservice.logout();
        console.log("get check data:", responseData.data.data[0]);
        if (responseData.data.status_code == 200) {
          this.Loadlogout = false;
          sessionStorage.clear();
          this.logoutDialog = false;
          this.$router.push({ name: "Login" });
          this.showAlertSuccess(responseData.data.message);
        } else {
          this.Loadlogout = false;
          this.logoutDialog = true;
          this.showAlertError(responseData.data.message);
        }
      } catch (err) {
        this.Loadlogout = false;
        this.logoutDialog = true;
        console.error(err);
        return;
      }
    },
    toggleFullScreen() {
      if (
        !document.fullscreenElement && // alternative standard method
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        this.Fullscreen = "Exit Fullscreen";
        this.Icon_text = "mdi-fullscreen-exit";
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        this.Fullscreen = "Fullscreen";
        this.Icon_text = "mdi-fullscreen";
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";

h2 {
  margin-bottom: 0rem;
}

li {
  list-style-type: none;
}

.row {
  border-top: 1px solid $gainsboro;
}

.list-group-flush {
  border: 1px solid $gainsboro;
}

.list-group-item {
  cursor: pointer;
}

.navbar-text {
  width: 100%;
  color: $dimgrey !important;
  font-weight: bold;
  border-bottom: 2px solid $dimgrey;
}

::v-deep .nav-link {
  padding: 0.5rem 0.5rem 0.25rem 0.5rem !important;
}

::v-deep .navbar-brand {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.menu-sec {
  margin: 10px;
  padding: 0;
  background: $white;
  text-align: center;
}

.logo_img {
  max-height: 40px;
}

.rt-mg-15 {
  margin-right: 15px;
}

.nav-mob-menu {
  padding: 3px 5px;
  background: transparent;
  color: $primary !important;
  border: 2px solid $primary !important;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 1040;
  background: $white !important;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.btn_transparent {
  width: 38px;
  height: 38px;
  padding: 0;
  font-size: 20px;
  background: transparent;
  border: none;
  color: $dimgrey;
}

.btn-secondary {
  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: $primary;
        background-color: transparent;
        border: none;
      }

      &:hover {
        color: $primary;
        background-color: transparent;
        border: none;
      }
    }
  }
}
</style>
