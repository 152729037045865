<template>
  <v-col class="text-center mb-1" cols="12">
    <v-col cols="12" class="text-center" v-if="mobilebreakdown()">{{
      $t("copyright_2022_qqpay_sdn_bhd")
    }}</v-col>
    <v-row no-gutters v-if="webbreakdown()">
      <v-col class="ma-0 pa-0">
        <div class="text-center">
          <v-btn
            to="/home"
            link
            title="Home"
            fab
            x-small
            elevation="8"
            active-class="active-col"
          >
            <i class="fa-solid fa-house-user faicon"></i>
          </v-btn>
        </div>
        <div active-class="active-col" class="menu-style text-center">Home</div>
      </v-col>
      <v-col class="ma-0 pa-0">
        <div class="text-center">
          <v-btn
            to="/transaction"
            link
            title="Send Money"
            fab
            x-small
            elevation="8"
            active-class="active-col"
          >
            <i class="fa fa-circle-dollar-to-slot faicon"></i>
          </v-btn>
        </div>
        <div class="menu-style text-center">Send Money</div>
      </v-col>
      <v-col class="ma-0 pa-0">
        <div class="text-center">
          <v-btn
            to="/beneficiarysummary"
            link
            title="Beneficiary"
            fab
            x-small
            elevation="8"
            active-class="active-col"
          >
            <i class="fa-solid fa-user-group faicon"></i>
          </v-btn>
        </div>
        <div class="menu-style text-center">Beneficiary</div>
      </v-col>
      <v-col class="ma-0 pa-0">
        <div class="text-center">
          <v-btn
            to="/profile"
            link
            title="Profile"
            fab
            x-small
            elevation="8"
            active-class="active-col"
          >
            <i class="fa-solid fa-user faicon"></i>
          </v-btn>
        </div>
        <div class="menu-style text-center">Profile</div>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
export default {
  name: "TitleBar",
  data: () => {
    return {};
  },
  methods: {
    mobilebreakdown() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return true;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
    webbreakdown() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";

.active-col {
  color: $primary;
}

.menu-style {
  font-size: 11px;
}
.faicon {
  font-size: 18px;
}
.faicon-send {
  font-size: 22px;
}
</style>
